import Vue from 'vue'
import $store from '../../store/index'
import $router from '../../router/index'

export async function login({ state }, payload) {
  state.errorLogin = false
  state.msgError = ''
  $store.state.loaderClover = true
  var form = new FormData()
  form.append('username', payload.correo)
  form.append('password', payload.password)
  form.append(
    'tokenCart',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/loginMailPassword',
    data: form,
  })
    .then(function(response) {
      state.dataUser = response.data.user
      state.directions = response.data.direcciones
      window.localStorage.setItem('accessToken', response.data.access_token)
      window.localStorage.setItem('tokenFavorite', response.data.tokenWhislist)
      window.localStorage.setItem('tokenCart', response.data.tokenCart)
    })
    .catch(e => {
      state.errorLogin = true
      state.msgErrorLogin = e.response.data.message
      console.log(e)
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}
export async function logOut({ state }) {
  state.errorLogin = false
  state.msgError = ''
  $store.state.loaderClover = true
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/logout',
  })
    .then(function() {
      state.dataUser = null
      state.directions = []
      $store.state.cart.dataCart = null
      $store.state.cart.productsCart = null
      window.localStorage.removeItem('accessToken')
      window.localStorage.removeItem('tokenFavorite')
      window.localStorage.removeItem('tokenCart')
      $router.push({ name: 'Home' }).catch(() => {})
    })
    .catch(e => {
      state.errorLogin = true
      state.msgErrorLogin = e.response.data.message
      console.log(e)
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}
export async function passwordToMail({ state }, payload) {
  state.errorLogin = false
  state.msgError = ''
  $store.state.loaderClover = true
  var form = new FormData()
  form.append('email', payload.correo)
  form.append('reenvio', payload.isReSend)
  form.append(
    'tokenCart',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/sendPasswordToMail',
    data: form,
  })
    .then(function(response) {
      console.log(response)
    })
    .catch(e => {
      state.errorLogin = true
      state.msgErrorLogin = e.response.data.message
      console.log(e)
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function sendCodEmailChangePassword({ state }, payload) {
  state.errorLogin = false
  state.msgError = ''
  $store.state.loaderClover = true
  var form = new FormData()
  form.append('email', payload.correo)
  form.append(
    'tokenCart',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/sendCodeResetPassword',
    data: form,
  })
    .then(function(response) {
      console.log(response)
    })
    .catch(e => {
      state.errorLogin = true
      state.msgErrorLogin = e.response.data.message
      console.log(e)
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function changePassword({ state }, payload) {
  state.errorLogin = false
  state.msgError = ''
  $store.state.loaderClover = true
  var form = new FormData()
  form.append('email', payload.correo)
  form.append('codigo_acceso', payload.codigo)
  form.append('password_new', payload.password)
  form.append('password_new_confirmed', payload.confirmPassword)
  form.append(
    'tokenCart',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/resetPassword',
    data: form,
  })
    .then(function(response) {
      state.dataUser = response.data.user
      state.directions = response.data.direcciones
      window.localStorage.setItem('accessToken', response.data.access_token)
      window.localStorage.setItem('tokenFavorite', response.data.tokenWhislist)
      window.localStorage.setItem('tokenCart', response.data.tokenCart)
    })
    .catch(e => {
      state.errorLogin = true
      state.msgErrorLogin = e.response.data.message
      console.log(e)
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function validateCodeLogin({ state }, payload) {
  state.errorLoginCod = false
  state.msgErrorCod = ''
  $store.state.loaderClover = true
  var form = new FormData()
  form.append('email', payload.correo)
  form.append('codigo_acceso', payload.codigo)
  form.append(
    'tokenCart',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/validateCodeLogin',
    data: form,
  })
    .then(function(response) {
      state.dataUser = response.data.user
      state.directions = response.data.direcciones
      window.localStorage.setItem('accessToken', response.data.access_token)
      window.localStorage.setItem('tokenFavorite', response.data.tokenWhislist)
      window.localStorage.setItem('tokenCart', response.data.tokenCart)
    })
    .catch(e => {
      state.errorLoginCod = true
      if (e.response.data.message) {
        state.msgErrorCod = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorCod = message
      }
      console.log(e)
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}
export async function register({ state }, payload) {
  state.errorRegister = false
  state.msgErrorRegister = ''
  $store.state.loaderClover = true
  var form = new FormData()
  form.append('email', payload.correo)
  form.append('nombres', payload.nombres)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/register',
    data: form,
  })
    .then(function() {})
    .catch(e => {
      state.errorRegister = true
      if (e.response.data.message) {
        state.msgErrorRegister = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorRegister = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}
export async function validateCodRegister({ state }, payload) {
  state.errorRegister = false
  state.msgErrorRegister = ''
  $store.state.loaderClover = true
  var form = new FormData()
  form.append('email', payload.correo)
  form.append('password', payload.password)
  form.append('password_confirmed', payload.passwordConfirm)
  form.append('codigo_acceso', payload.codigo)
  form.append('tokenCart', window.localStorage.getItem('tokenCart'))
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/completeRegister',
    data: form,
  })
    .then(function(response) {
      state.dataUser = response.data.user
      state.directions = response.data.direcciones
      window.localStorage.setItem('accessToken', response.data.access_token)
      window.localStorage.setItem('tokenFavorite', response.data.tokenWhislist)
      window.localStorage.setItem('tokenCart', response.data.tokenCart)
    })
    .catch(e => {
      state.errorRegister = true
      if (e.response.data.message) {
        state.msgErrorRegister = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorRegister = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}
export async function validateAuth({ state }) {
  state.errorRegister = false
  state.msgErrorRegister = ''
  $store.state.loaderClover = true
  var form = new FormData()
  form.append('accessToken', window.localStorage.getItem('accessToken'))
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/validate_token',
    data: form,
  })
    .catch(e => {
      if (e.response.status == 401) {
        window.localStorage.removeItem('accessToken')
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}
export async function getDataProfile({ state }) {
  state.errorRegister = false
  state.msgErrorRegister = ''
  // $store.state.loaderClover = true;
  var form = new FormData()
  form.append('accessToken', window.localStorage.getItem('accessToken'))
  await Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/profile',
    data: form,
  })
    .then(function(response) {
      state.dataUser = response.data.user
      state.directions = response.data.direcciones
      state.pedidos = response.data.pedidos.activos
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      // $store.state.loaderClover = false;
    })
}

export async function updateDataUser({ state }, payload) {
  state.errorUpdateInfo = false
  state.msgErrorUpdateInfo = ''
  $store.state.loaderClover = true
  var form = new FormData()
  form.append('nombres', payload.nombres)
  form.append('apellidos', payload.apellidos)
  form.append('documento', payload.documento)
  form.append('tipodoc', payload.tipoDoc)
  form.append('celular', payload.celular)
  form.append('genero', payload.genero)
  form.append('dateday', payload.day)
  form.append('datemonth', payload.month)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/profile/update',
    data: form,
  })
    .then(response => {
      state.dataUser = response.data.user
    })
    .catch(e => {
      state.errorUpdateInfo = true
      if (e.response.data.message) {
        state.msgErrorUpdateInfo = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorUpdateInfo = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}
export async function addDir({ state }, payload) {
  $store.state.loaderClover = true
  state.errorDir = false
  state.msgErrorDir = ''
  var form = new FormData()
  form.append('direccion', payload.dir)
  form.append('ciudad_id', payload.mun)
  form.append('departamento_id', payload.dep)
  form.append('barrio', payload.barrio)
  form.append('observaciones', payload.observaciones)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/users/agregar_direccion',
    data: form,
  })
    .then(function(response) {
      state.directions = response.data.direcciones
    })
    .catch(e => {
      state.errorDir = true
      let message = ''
      for (const property in e.response.data.errors) {
        message = message + e.response.data.errors[property] + ' '
      }
      state.msgErrorDir = message
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}
export async function updateDir({ state }, payload) {
  $store.state.loaderClover = true
  state.errorDir = false
  state.msgErrorDir = ''
  var form = new FormData()
  form.append('direccion_id', payload.idDir)
  form.append('direccion', payload.dir)
  form.append('ciudad_id', payload.mun)
  form.append('departamento_id', payload.dep)
  form.append('barrio', payload.barrio)
  form.append('observaciones', payload.observaciones)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/users/editar_direccion',
    data: form,
  })
    .then(function(response) {
      state.directions = response.data.direcciones
    })
    .catch(e => {
      state.errorDir = true
      let message = ''
      for (const property in e.response.data.errors) {
        message = message + e.response.data.errors[property] + ' '
      }
      state.msgErrorDir = message
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}
export async function deleteDir({ state }, payload) {
  $store.state.loaderClover = true
  var form = new FormData()
  form.append('direccion_id', payload.idDir)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/users/eliminar_direccion',
    data: form,
  })
    .then(function(response) {
      state.directions = response.data.direcciones
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}
export async function getCiudades({ state }) {
  await Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/departamentos/envios',
  })
    .then(function(response) {
      state.departamentos = response.data.departamentos
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      //
    })
}
export async function updatePassword({ state }, payload) {
  state.errorUpdatePassword = false
  state.msgErrorupdatePassword = ''
  state.updatePasswordSuccessfull = false
  $store.state.loaderClover = true
  var form = new FormData()
  form.append('password_new', payload.newPassword)
  form.append('password_actual', payload.password)
  await Vue.axios({
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/auth/profile/update_password',
    data: form,
  })
    .then(() => {
      state.updatePasswordSuccessfull = true
    })
    .catch(e => {
      state.errorUpdatePassword = true
      if (e.response.data.message) {
        state.msgErrorupdatePassword = e.response.data.message
      } else {
        state.errorGetInfoOrder = true
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorupdatePassword = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function getInfoOrder({ state }, payload) {
  state.errorGetInfoOrder = false
  state.msgGetInfoOrder = ''
  $store.state.loaderClover = true
  var form = new FormData()
  form.append('email', payload.email)
  form.append('pedido_id', payload.pedidoId)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/pedidos/rastrear_pedido',
    data: form,
  })
    .then(function(response) {
      state.dataOrder = response.data.pedido
    })
    .catch(e => {
      if (e.response.data.message) {
        state.msgGetInfoOrder = e.response.data.message
      } else {
        state.errorGetInfoOrder = true
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgGetInfoOrder = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function validatePedidoDevolucion({ state }, payload) {
  $store.state.loaderClover = true
  state.errorDevolucion = false
  state.msgErrorDevolucion = ''
  state.infoDevolucion = null
  let data = new FormData()
  data.append('pedido_id', payload.pedidoId)
  data.append('email', payload.email)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/pedidos/devoluciones/generar_token_acceso',
    data: data,
  })
    .then(response => {
      if (!response.data.message) {
        state.infoDevolucion = response.data
      }
    })
    .catch(e => {
      state.errorDevolucion = true
      if (e.response.data.message) {
        state.msgErrorDevolucion = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorDevolucion = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function validateTokenDevolucion({ state }, payload) {
  $store.state.loaderClover = true
  state.errorDevolucion = false
  state.msgErrorDevolucion = ''
  state.infoDevolucion = null
  let data = new FormData()
  data.append('pedido_id', payload.pedidoId)
  data.append('email', payload.email)
  data.append('codigoAcceso', payload.codigo)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/pedidos/devoluciones/validar_token_acceso',
    data: data,
  })
    .then(response => {
      state.infoDevolucion = response.data
    })
    .catch(e => {
      state.errorDevolucion = true
      if (e.response.data.message) {
        state.msgErrorDevolucion = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorDevolucion = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}

export async function enviarDevolucion({ state }, payload) {
  $store.state.loaderClover = true
  state.errorDevolucion = false
  state.msgErrorDevolucion = ''
  let data = new FormData()
  data.append('pedido_id', payload.pedidoId)
  data.append('email', payload.email)
  data.append('codigoAcceso', payload.codigo)
  payload.pedidoDetalle.forEach(e => {
    data.append('motivos_ids[]', e.motivo_id)
    data.append('pedido_detalles_ids[]', e.pedido_detalle_id)
  })
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/pedidos/devoluciones/registrar_devolucion',
    data: data,
  })
    .catch(e => {
      state.errorDevolucion = true
      if (e.response.data.message) {
        state.msgErrorDevolucion = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorDevolucion = message
      }
    })
    .finally(() => {
      $store.state.loaderClover = false
    })
}
