<template>
  <b-modal
    id="modal-login"
    v-model="modalLogin"
    centered
    hide-header
    hide-footer
  >
    <div v-if="isLogin" class="container-modal-login col-12 col-md-7">
      <div v-if="stepIsLogin > 1" class="back-login" @click="stepIsLogin = 1">
        <img
          src="@/assets/icons/arrow-left.svg"
          style="filter: opacity(0.6); max-width: 12px; margin-right: 0.4rem"
          alt="arrow back"
        />
        Volver
      </div>
      <h4 class="mb-4">Iniciar sesión</h4>
      <div v-if="stepIsLogin == 1">
        <p>Elige una de las opciones para confirmar tu identidad.</p>
        <button class="bnt-primary w-100 mb-4" @click="stepIsLogin = 2">
          Ingresar con mail y contraseña
        </button>
        <button class="bnt-outline w-100 mb-4" @click="stepIsLogin = 3">
          Recibir clave de acceso por correo
        </button>
      </div>
      <div v-if="stepIsLogin == 2">
        <div class="input-simple-clover mb-4">
          <label
            :class="invalidEmail ? 'invalid' : ''"
            class="label-input"
            :style="emailLogin ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Correo electrónico</label
          >
          <input
            id="email"
            v-model="emailLogin"
            type="email"
            placeholder="Correo electrónico"
            autocomplete="email"
            :class="invalidEmail ? 'invalid-error' : ''"
            @blur="validateBlur('emailLogin')"
            @focus="invalidEmail = false"
          />
          <span v-if="invalidEmail" class="inavalida-input">{{
            emailLogin ? 'Correo invalido' : 'Campo requerido'
          }}</span>
        </div>
        <div class="input-simple-clover mb-4">
          <label
            :class="invalidPassword ? 'invalid' : ''"
            class="label-input"
            :style="passwordLogin ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Contraseña</label
          >
          <input
            v-model="passwordLogin"
            :class="invalidPassword ? 'invalid-error' : ''"
            placeholder="Contraseña"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="password"
            @blur="validateBlur('passwordLogin')"
            @focus="invalidPassword = false"
          />
          <span v-if="invalidPassword" class="inavalida-input">{{
            passwordLogin ? 'Apellido invalido' : 'Campo requerido'
          }}</span>
          <svg
            class="show-password"
            xmlns="http://www.w3.org/2000/svg"
            width="24.207"
            height="23.414"
            viewBox="0 0 24.207 23.414"
            @click="showPassword = !showPassword"
          >
            <g
              id="Grupo_5755"
              data-name="Grupo 5755"
              transform="translate(-407 -937.793)"
            >
              <g
                id="Grupo_5027"
                data-name="Grupo 5027"
                transform="translate(-1080.5 601.643)"
              >
                <path
                  id="Trazado_2344"
                  data-name="Trazado 2344"
                  d="M1510.5,347.674s-3.826,7.174-11,7.174-11-7.174-11-7.174,3.826-7.174,11-7.174S1510.5,347.674,1510.5,347.674Z"
                  transform="translate(0)"
                  fill="none"
                  stroke="#949494"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <circle
                  id="Elipse_582"
                  data-name="Elipse 582"
                  cx="4.304"
                  cy="4.304"
                  r="4.304"
                  transform="translate(1495.196 343.37)"
                  fill="none"
                  stroke="#949494"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
              <line
                v-if="!showPassword"
                id="Línea_1365"
                data-name="Línea 1365"
                y1="22"
                x2="22"
                transform="translate(408.5 938.5)"
                fill="none"
                stroke="#949494"
                stroke-width="2"
              />
            </g>
          </svg>
        </div>
        <p style="cursor: pointer; text-align: end" @click="stepIsLogin = 4">
          Recuperar contraseña
        </p>
        <button
          class="bnt-primary w-100 mb-4 fw-bold"
          :style="
            !validateEmail && !(passwordLogin.length > 4) ? 'opacity: .6' : ''
          "
          :disabled="!validateEmail && !(passwordLogin.length > 4)"
          @click="loaderClover ? '' : validateLogin()"
        >
          Ingresar
        </button>
        <span v-if="errorLogin" class="text-error">{{ msgErrorLogin }}</span>
      </div>
      <div v-if="stepIsLogin == 3">
        <div v-if="!sendCod">
          <p>Escribe tu correo electrónico para solicitar clave de acceso</p>
          <div class="input-simple-clover mb-4">
            <label
              :class="invalidEmailbyCorreo ? 'invalid' : ''"
              class="label-input"
              :style="
                emailLoginbyCorreo ? 'top: -14px;' : 'opacity: 0; z-index: -1;'
              "
              >Correo electrónico</label
            >
            <input
              id="email"
              v-model="emailLoginbyCorreo"
              type="email"
              placeholder="Correo electrónico"
              autocomplete="email"
              :class="invalidEmailbyCorreo ? 'invalid-error' : ''"
              @blur="validateBlur('emailClaveCorreo')"
              @focus="invalidEmailbyCorreo = false"
            />
            <span v-if="invalidEmailbyCorreo" class="inavalida-input">{{
              emailLoginbyCorreo ? 'Correo invalido' : 'Campo requerido'
            }}</span>
          </div>
          <button
            class="bnt-primary w-100 mb-4 fw-bold"
            :style="!validateEmailClaveCorreo ? 'opacity: .6' : ''"
            :disabled="!validateEmailClaveCorreo"
            @click="loaderClover ? '' : validateLoginByCorreo(0)"
          >
            Continuar
          </button>
        </div>
        <div v-else>
          <p class="mb-4">
            Hemos enviado un código de acceso a tu correo electrónico.
          </p>
          <div class="input-simple-clover mb-4">
            <label
              class="label-input"
              :style="
                emailLoginbyCorreo ? 'top: -14px;' : 'opacity: 0; z-index: -1;'
              "
              >Código de acceso</label
            >
            <input
              v-model="codEmailByCorreo"
              placeholder="Código de acceso"
              type="text"
            />
          </div>
          <button
            class="bnt-primary w-100 mb-4 fw-bold"
            @click="loaderClover ? '' : validateCodByCorreo()"
          >
            Ingresar
          </button>
          <div class="w-100">
            <p v-if="countdown" class="text-center">{{ messageReSend }}</p>
            <p
              v-else
              class="text-center"
              style="cursor: pointer;"
              @click="loaderClover ? '' : validateLoginByCorreo(1)"
            >
              Reenviar código
            </p>
          </div>
        </div>
        <span v-if="errorLoginCod" class="text-error">{{ msgErrorCod }}</span>
      </div>
      <div v-if="stepIsLogin == 4">
        <div v-if="!sendCodChangePassword">
          <p>Escribe tu correo electrónico para solicitar clave de acceso</p>
          <div class="input-simple-clover mb-4">
            <label
              :class="invalidEmailChangeCorreo ? 'invalid' : ''"
              class="label-input"
              :style="
                emailChangeCorreo ? 'top: -14px;' : 'opacity: 0; z-index: -1;'
              "
              >Correo electrónico</label
            >
            <input
              id="email"
              v-model="emailChangeCorreo"
              type="email"
              placeholder="Correo electrónico"
              autocomplete="email"
              :class="invalidEmailChangeCorreo ? 'invalid-error' : ''"
              @blur="validateBlur('emailChangeCorreo')"
              @focus="invalidEmailChangeCorreo = false"
            />
            <span v-if="invalidEmailChangeCorreo" class="inavalida-input">{{
              emailChangeCorreo ? 'Correo invalido' : 'Campo requerido'
            }}</span>
          </div>
          <button
            class="bnt-primary w-100 mb-4 fw-bold"
            :style="invalidEmailChangeCorreo ? 'opacity: .6' : ''"
            :disabled="invalidEmailChangeCorreo"
            @click="loaderClover ? '' : validateChangePasswordByCorreo()"
          >
            Ingresar
          </button>
        </div>
        <div v-else>
          <p class="mb-4">
            Escribe tu correo electrónico para solicitar clave de acceso hemos
            enviado un código de seguridad a tu correo.
          </p>
          <div class="input-simple-clover mb-4">
            <label
              class="label-input"
              :style="
                codEmailChangePassword
                  ? 'top: -14px;'
                  : 'opacity: 0; z-index: -1;'
              "
              >Código de acceso</label
            >
            <input
              v-model="codEmailChangePassword"
              placeholder="Código de acceso"
              type="number"
              @blur="validateBlur('codEmailChangePassword')"
            />
            <span
              v-if="invalidCodEmailChangePassword"
              class="inavalida-input"
              >{{
                codEmailChangePassword ? 'Correo invalido' : 'Campo requerido'
              }}</span
            >
          </div>
          <div class="input-simple-clover mb-4">
            <label
              class="label-input"
              :style="
                passwordChangePassword
                  ? 'top: -14px;'
                  : 'opacity: 0; z-index: -1;'
              "
              >Ingresa nueva contraseña</label
            >
            <input
              v-model="passwordChangePassword"
              placeholder="Ingresa nueva contraseña"
              :type="passwordPasswordChangePassword ? 'text' : 'password'"
              @blur="validateBlur('passwordChangePassword')"
            />
            <span
              v-if="invalidPasswordChangePassword"
              class="inavalida-input"
              >{{
                passwordChangePassword
                  ? 'Minimo 8 caracteres'
                  : 'Campo requerido'
              }}</span
            >
            <svg
              class="show-password"
              xmlns="http://www.w3.org/2000/svg"
              width="24.207"
              height="23.414"
              viewBox="0 0 24.207 23.414"
              @click="
                passwordPasswordChangePassword = !passwordPasswordChangePassword
              "
            >
              <g
                id="Grupo_5755"
                data-name="Grupo 5755"
                transform="translate(-407 -937.793)"
              >
                <g
                  id="Grupo_5027"
                  data-name="Grupo 5027"
                  transform="translate(-1080.5 601.643)"
                >
                  <path
                    id="Trazado_2344"
                    data-name="Trazado 2344"
                    d="M1510.5,347.674s-3.826,7.174-11,7.174-11-7.174-11-7.174,3.826-7.174,11-7.174S1510.5,347.674,1510.5,347.674Z"
                    transform="translate(0)"
                    fill="none"
                    stroke="#949494"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                  <circle
                    id="Elipse_582"
                    data-name="Elipse 582"
                    cx="4.304"
                    cy="4.304"
                    r="4.304"
                    transform="translate(1495.196 343.37)"
                    fill="none"
                    stroke="#949494"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </g>
                <line
                  v-if="!passwordPasswordChangePassword"
                  id="Línea_1365"
                  data-name="Línea 1365"
                  y1="22"
                  x2="22"
                  transform="translate(408.5 938.5)"
                  fill="none"
                  stroke="#949494"
                  stroke-width="2"
                />
              </g>
            </svg>
          </div>
          <div class="input-simple-clover mb-4">
            <label
              class="label-input"
              :style="
                confirmPasswordChangePassword
                  ? 'top: -14px;'
                  : 'opacity: 0; z-index: -1;'
              "
              >Confirma nueva contraseña</label
            >
            <input
              v-model="confirmPasswordChangePassword"
              placeholder="Confirma nueva contraseña"
              :type="passwordChangePasswordChangePassword ? 'text' : 'password'"
              @blur="validateBlur('confirmPasswordChangePassword')"
            />
            <span
              v-if="invalidConfirmPasswordChangePassword"
              class="inavalida-input"
              >{{
                confirmPasswordChangePassword
                  ? 'Las contraseñas no coinciden'
                  : 'Campo requerido'
              }}</span
            >
            <svg
              class="show-password"
              xmlns="http://www.w3.org/2000/svg"
              width="24.207"
              height="23.414"
              viewBox="0 0 24.207 23.414"
              @click="
                passwordChangePasswordChangePassword = !passwordChangePasswordChangePassword
              "
            >
              <g
                id="Grupo_5755"
                data-name="Grupo 5755"
                transform="translate(-407 -937.793)"
              >
                <g
                  id="Grupo_5027"
                  data-name="Grupo 5027"
                  transform="translate(-1080.5 601.643)"
                >
                  <path
                    id="Trazado_2344"
                    data-name="Trazado 2344"
                    d="M1510.5,347.674s-3.826,7.174-11,7.174-11-7.174-11-7.174,3.826-7.174,11-7.174S1510.5,347.674,1510.5,347.674Z"
                    transform="translate(0)"
                    fill="none"
                    stroke="#949494"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                  <circle
                    id="Elipse_582"
                    data-name="Elipse 582"
                    cx="4.304"
                    cy="4.304"
                    r="4.304"
                    transform="translate(1495.196 343.37)"
                    fill="none"
                    stroke="#949494"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </g>
                <line
                  v-if="!passwordChangePasswordChangePassword"
                  id="Línea_1365"
                  data-name="Línea 1365"
                  y1="22"
                  x2="22"
                  transform="translate(408.5 938.5)"
                  fill="none"
                  stroke="#949494"
                  stroke-width="2"
                />
              </g>
            </svg>
          </div>
          <button
            class="bnt-primary w-100 mb-4 fw-bold"
            @click="loaderClover ? '' : sendNewPassword()"
          >
            Ingresar
          </button>
        </div>
        <span v-if="errorLogin" class="text-error pb-3 text-center">{{
          msgErrorLogin
        }}</span>
      </div>
      <div class="container-footer-modal">
        <p class="mb-0">
          ¿Aún no tienes una cuenta?
          <span
            class="fw-bold"
            style="cursor: pointer"
            @click="$store.state.user.isLogin = !$store.state.user.isLogin"
            >Regístrate</span
          >
        </p>
      </div>
    </div>
    <div
      v-else
      class="container-modal-login col-12 col-md-7"
      :class="registerStep == 2 ? 'pb-0' : ''"
    >
      <h4 class="mb-4">Crear cuenta</h4>
      <div v-if="registerStep == 1">
        <p class="mb-4">¿Cúal es tu nombre?</p>
        <div class="input-simple-clover mb-4">
          <label
            :class="invalidNombreRegistro ? 'invalid' : ''"
            class="label-input"
            :style="nombreRegistro ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Nombre</label
          >
          <input
            v-model="nombreRegistro"
            :class="invalidNombreRegistro ? 'invalid-error' : ''"
            placeholder="Ej: Sofía"
            type="text"
            autocomplete="given-name"
            @blur="validateBlur('nombreRegistro')"
            @focus="invalidNombreRegistro = false"
          />
          <span v-if="invalidNombreRegistro" class="inavalida-input">{{
            nombreRegistro ? 'Nombre invalido' : 'Campo requerido'
          }}</span>
        </div>
        <p class="mb-4">Ingresa el correo para recibir código de acceso</p>
        <div class="input-simple-clover mb-4">
          <label
            :class="invalidEmailRegistro ? 'invalid' : ''"
            class="label-input"
            :style="correoRegistro ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Correo electrónico</label
          >
          <input
            id="email"
            v-model="correoRegistro"
            type="email"
            placeholder="Correo electrónico"
            autocomplete="email"
            :class="invalidEmailRegistro ? 'invalid-error' : ''"
            @blur="validateBlur('emailRegistro')"
            @focus="invalidEmailRegistro = false"
          />
          <span v-if="invalidEmailRegistro" class="inavalida-input">{{
            correoRegistro ? 'Correo invalido' : 'Campo requerido'
          }}</span>
        </div>
        <button
          class="bnt-primary w-100 mb-4 fw-bold"
          @click="loaderClover ? '' : validateDataRegister()"
        >
          Registrarme
        </button>
        <span v-if="errorRegister" class="text-error">{{
          msgErrorRegister
        }}</span>
        <div class="container-footer-modal">
          <p class="mb-0">
            ¿Ya tienes una cuenta?
            <span
              class="fw-bold"
              style="cursor: pointer"
              @click="$store.state.user.isLogin = !$store.state.user.isLogin"
              >Iniciar sesión</span
            >
          </p>
        </div>
      </div>
      <div v-if="registerStep == 2">
        <p class="mb-4">
          Hola <b>{{ nombreRegistro }}</b
          >, ingresa el código que enviamos a: <b>{{ correoRegistro }}</b>
        </p>
        <div class="input-simple-clover mb-4">
          <label
            :class="invalidCodRegister ? 'invalid' : ''"
            class="label-input"
            :style="codRegister ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Código de acceso</label
          >
          <input
            v-model="codRegister"
            :class="invalidCodRegister ? 'invalid-error' : ''"
            placeholder="Código de acceso"
            type="number"
            @blur="validateBlur('codRegister')"
            @focus="invalidCodRegister = false"
          />
          <span v-if="invalidCodRegister" class="inavalida-input">{{
            codRegister ? 'código invalido' : 'Campo requerido'
          }}</span>
        </div>
        <p class="mb-4">Crear una nueva contraseña</p>
        <div class="input-simple-clover mb-4">
          <label
            :class="invalidPasswordRegister ? 'invalid' : ''"
            class="label-input"
            :style="password ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Ingresar clave</label
          >
          <input
            v-model="password"
            :class="invalidPasswordRegister ? 'invalid-error' : ''"
            placeholder="Ingresar clave"
            type="password"
            @blur="validateBlur('passwordRegister')"
            @focus="invalidPasswordRegister = false"
          />
          <span v-if="invalidPasswordRegister" class="inavalida-input">{{
            password ? 'Min 8 caracteres' : 'Campo requerido'
          }}</span>
        </div>
        <div class="input-simple-clover mb-4">
          <label
            :class="invalidConfirmPassword ? 'invalid' : ''"
            class="label-input"
            :style="
              confirmPassword ? 'top: -14px;' : 'opacity: 0; z-index: -1;'
            "
            >Confirmar clave</label
          >
          <input
            v-model="confirmPassword"
            :class="invalidConfirmPassword ? 'invalid-error' : ''"
            placeholder="Confirmar clave"
            type="password"
            @blur="validateBlur('confirmPasswordRegister')"
            @focus="invalidConfirmPassword = false"
          />
          <span v-if="invalidConfirmPassword" class="inavalida-input">{{
            confirmPassword ? 'La contraseña no coincide' : 'Campo requerido'
          }}</span>
        </div>
        <button
          class="bnt-primary w-100 mb-4 fw-bold"
          @click="loaderClover ? '' : validateCodRegisterStep2()"
        >
          Crear cuenta
        </button>
        <span v-if="errorRegister" class="text-error">{{
          msgErrorRegister
        }}</span>
      </div>
    </div>
    <div class="container-img-modal col-5 d-none d-md-flex">
      <img
        v-if="isLogin"
        src="@/assets/imgs/img-login-portada.png"
        class="mb-auto"
      />
      <img
        v-else
        src="@/assets/imgs/img-register-portada.png"
        class="mb-auto"
      />
    </div>
    <img
      src="@/assets/icons/close-modal.svg"
      class="icon-close-modal"
      @click="modalLogin = false"
    />
  </b-modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      modalLogin: false,
      stepIsLogin: 1,
      showPassword: false,
      /** Variables para inicio de sesion */
      emailLogin: '',
      invalidEmail: false,
      passwordLogin: '',
      invalidPassword: false,
      /** Variables para inicio de sesion Correo */
      emailLoginbyCorreo: '',
      invalidEmailbyCorreo: false,
      sendCod: false,
      codEmailByCorreo: '',
      messageReSend: '',
      countdown: 0,
      /** Variables para cambiar contraseña */
      emailChangeCorreo: '',
      invalidEmailChangeCorreo: false,
      sendCodChangePassword: false,
      codEmailChangePassword: '',
      invalidCodEmailChangePassword: false,
      passwordChangePassword: '',
      passwordPasswordChangePassword: false,
      invalidPasswordChangePassword: false,
      confirmPasswordChangePassword: '',
      changePasssword: false,
      passwordChangePasswordChangePassword: false,
      invalidConfirmPasswordChangePassword: false,
      /** Variables para registro de cuenta */
      nombreRegistro: '',
      invalidNombreRegistro: false,
      correoRegistro: '',
      invalidEmailRegistro: false,
      /** Variables para codigo y password */
      registerStep: 1,
      codRegister: '',
      invalidCodRegister: false,
      password: '',
      invalidPasswordRegister: false,
      confirmPassword: '',
      invalidConfirmPassword: false,
    }
  },
  computed: {
    ...mapState('user', [
      'dataUser',
      'errorRegister',
      'msgErrorRegister',
      'errorLogin',
      'msgErrorLogin',
      'isLogin',
      'errorLoginCod',
      'msgErrorCod',
      'loaderClover',
    ]),
    /* eslint-disable */
    validateEmail() {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.emailLogin)
    },
    validateEmailClaveCorreo() {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.emailLoginbyCorreo)
    },
    validateEmailChangeCorreo() {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.emailChangeCorreo)
    },
    validateEmailByCorreo() {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.emailLoginbyCorreo)
    },
    validateEmailRegister() {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.correoRegistro)
    },
  },
  watch: {
    isLogin() {
      if (!this.isLogin) {
        if (this.validateEmail) {
          this.correoRegistro = this.emailLogin
        }
      }
    },
    modalLogin() {
      if (!this.modalLogin) {
        this.stepIsLogin = 1
        this.emailLogin = ''
        this.invalidEmail = false
        this.passwordLogin = ''
        this.invalidPassword = false
        this.emailLoginbyCorreo = ''
        this.invalidEmailbyCorreo = false
        this.sendCod = false
        this.codEmailByCorreo = ''
        this.emailChangeCorreo = ''
        this.invalidEmailChangeCorreo = false
        this.sendCodChangePassword = false
        this.codEmailChangePassword = ''
        this.passwordChangePassword = ''
        ;(this.passwordPasswordChangePassword = false),
          (this.invalidPasswordChangePassword = false)
        ;(this.passwordChangePasswordChangePassword = false),
          (this.confirmPasswordChangePassword = '')
        this.invalidConfirmPasswordChangePassword = false
        this.nombreRegistro = ''
        this.invalidNombreRegistro = false
        this.correoRegistro = ''
        this.invalidEmailRegistro = false
        this.registerStep = 1
        this.codRegister = ''
        this.invalidCodRegister = false
        this.password = ''
        this.invalidPasswordRegister = false
        this.confirmPassword = ''
        this.invalidConfirmPassword = false
        this.$store.state.user.isLogin = true
        this.$store.state.user.msgErrorRegister = ''
        this.$store.state.user.errorRegister = false
        this.$store.state.user.msgErrorLogin = ''
        this.$store.state.user.errorLogin = false
        this.$store.state.user.errorLoginCod = false
        this.$store.state.user.msgErrorCod = ''
        this.changePasssword = false
        this.changePasssword = false
        this.passwordChangePasswordChangePassword = false
        this.passwordPasswordChangePassword = false
        this.invalidCodEmailChangePassword = false
      }
    },
    stepIsLogin(to, from) {
      this.$store.state.user.isLogin = true
      this.$store.state.user.msgErrorRegister = ''
      this.$store.state.user.errorRegister = false
      this.$store.state.user.msgErrorLogin = ''
      this.$store.state.user.errorLogin = false
      this.$store.state.user.errorLoginCod = false
      this.$store.state.user.msgErrorCod = ''
      if (from > to) {
        if (from == 3) {
          this.emailLoginbyCorreo = ''
          this.invalidEmailbyCorreo = false
          this.sendCod = false
          this.codEmailByCorreo = ''
        }
      }
    },
  },
  methods: {
    ...mapActions('user', [
      'register',
      'validateCodRegister',
      'validateCodeLogin',
      'passwordToMail',
      'sendCodEmailChangePassword',
      'changePassword',
      'login',
      'getDataProfile',
    ]),
    ...mapActions('cart', ['getProductsCart']),
    validateBlur(input) {
      if (input == 'emailLogin') {
        this.invalidEmail = !this.validateEmail
      } else if (input == 'passwordLogin') {
        this.invalidPassword = !(this.passwordLogin.length > 3)
      } else if (input == 'nombreRegistro') {
        this.invalidNombreRegistro = !(this.nombreRegistro.length > 2)
      } else if (input == 'emailRegistro') {
        this.invalidEmailRegistro = !this.validateEmailRegister
      } else if (input == 'codRegister') {
        this.invalidCodRegister = !(this.codRegister.length == 6)
      } else if (input == 'passwordRegister') {
        this.invalidPasswordRegister = !(this.password.length >= 8)
      } else if (input == 'confirmPasswordRegister') {
        this.invalidConfirmPassword = !(
          this.confirmPassword == this.password &&
          this.confirmPassword.length >= 8
        )
      } else if (input == 'emailClaveCorreo') {
        this.invalidEmailbyCorreo = !this.validateEmailClaveCorreo
      } else if (input == 'emailChangeCorreo') {
        this.invalidEmailChangeCorreo = !this.validateEmailChangeCorreo
      } else if (input == 'codEmailChangePassword') {
        this.invalidCodEmailChangePassword = !(
          this.codEmailChangePassword.length > 3
        )
      } else if (input == 'passwordChangePassword') {
        this.invalidPasswordChangePassword = !(
          this.passwordChangePassword.length >= 8
        )
      } else if (input == 'confirmPasswordChangePassword') {
        this.invalidConfirmPasswordChangePassword = !(
          this.confirmPasswordChangePassword == this.passwordChangePassword &&
          this.confirmPasswordChangePassword.length >= 8
        )
      }
    },
    async validateLogin() {
      if (this.passwordLogin.length > 3 && this.validateEmail) {
        await this.login({
          correo: this.emailLogin,
          password: this.passwordLogin,
        }).then(() => {
          if (!this.errorLogin) {
            this.modalLogin = false
            this.getProductsCart()
            this.getDataProfile()
          }
        })
      } else {
        this.invalidEmail = !this.validateEmail
        this.invalidPassword = !(this.passwordLogin.length > 3)
      }
    },
    async validateLoginByCorreo(isReSend) {
      if (this.validateEmailByCorreo) {
        await this.passwordToMail({ correo: this.emailLoginbyCorreo, isReSend: isReSend }).then(
          () => {
            if (!this.errorLogin) {
              this.startCounter(120)
              this.sendCod = true
            }
          },
        )
      }
    },
    startCounter(time) {
      if (time > 0) {
        this.countdown = true
        let minutes = parseInt(time / 60).toString()
        let seconds = (time - (parseInt(minutes) * 60)).toString()
        this.messageReSend = `Reenviar código en ${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`
        setTimeout(() => {
          this.startCounter(time - 1)
        }, 1000);
      } else {
        this.countdown = false
        this.messageReSend = ''
      }
    },
    async validateChangePasswordByCorreo() {
      if (this.validateEmailChangeCorreo) {
        await this.sendCodEmailChangePassword({
          correo: this.emailChangeCorreo,
        }).then(() => {
          if (!this.errorLogin) {
            this.sendCodChangePassword = true
          }
        })
      } else {
        this.invalidEmailChangeCorreo = !this.validateEmailChangeCorreo
      }
    },
    async sendNewPassword() {
      if (
        this.validateEmailChangeCorreo &&
        this.confirmPasswordChangePassword == this.passwordChangePassword &&
        this.confirmPasswordChangePassword.length >= 8 &&
        this.passwordChangePassword.length >= 8
      ) {
        await this.changePassword({
          correo: this.emailChangeCorreo,
          codigo: this.codEmailChangePassword,
          password: this.passwordChangePassword,
          confirmPassword: this.confirmPasswordChangePassword,
        }).then(() => {
          if (!this.errorLogin) {
            this.modalLogin = false
            this.getProductsCart()
            this.getDataProfile()
          }
        })
      }
    },
    async validateCodByCorreo() {
      if (this.codEmailByCorreo.length > 4) {
        await this.validateCodeLogin({
          correo: this.emailLoginbyCorreo,
          codigo: this.codEmailByCorreo,
        }).then(() => {
          console.log(this.errorLoginCod)
          if (!this.errorLoginCod) {
            this.modalLogin = false
            this.sendCod = true
          }
        })
      }
    },
    async validateDataRegister() {
      if (this.nombreRegistro.length > 2 && this.validateEmailRegister) {
        await this.register({
          nombres: this.nombreRegistro,
          correo: this.correoRegistro,
        }).then(() => {
          if (!this.errorRegister) {
            this.registerStep += 1
          }
        })
      } else {
        this.invalidEmailRegistro = !this.validateEmailRegister
        this.invalidNombreRegistro = !(this.nombreRegistro.length > 2)
      }
    },
    async validateCodRegisterStep2() {
      if (
        this.codRegister.length == 6 &&
        this.password.length >= 8 &&
        this.confirmPassword == this.password &&
        this.confirmPassword.length >= 8
      ) {
        await this.validateCodRegister({
          correo: this.correoRegistro,
          password: this.password,
          passwordConfirm: this.confirmPassword,
          codigo: this.codRegister,
        }).then(() => {
          //TODO: falta manadar a llamar y setear los demas datos... getProducts etc
          if (!this.errorRegister) {
            // CL-SignUp
            window.dataLayer.push({
              event: 'CL-SignUp',
              name: this.nombreRegistro,
              email: this.correoRegistro,
            })
            this.modalLogin = false
          }
        })
      } else {
        this.invalidCodRegister = !(this.codRegister.length == 6)
        this.invalidPasswordRegister = !(this.password.length >= 8)
        this.invalidConfirmPassword = !(
          this.confirmPassword == this.password &&
          this.confirmPassword.length >= 8
        )
      }
    },
    async validateChangeClave() {
      console.log('asdasdasdasdasdasdasdsdasdasd')
    },
    async validateCodByChangePassword() {
      console.log('asdasdasdasdasdasdasdsdasdasd')
    },
  },
}
</script>
